<template>
  <section>
    <h3>Load Details</h3>
  </section>
</template>

<script>
export default {
}
</script>
